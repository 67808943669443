const LeaderboardCookie = {

    setup: function() {
        this.$leaderboard = $('.leaderboard');
        this.$btn_close = this.$leaderboard.find('.button__close');

        if (this.$leaderboard.length === 0) {
            this.$leaderboard.hide();
            document.documentElement.style.setProperty('--breadcrumb-position', 95 + "px")
            document.documentElement.style.setProperty('--top-position-menu', 0 + "px")
            document.documentElement.style.setProperty('--page-margin',  ($('#megaMenu').innerHeight() || $('.desktop-menu').find(".header__row").innerHeight() || $('.mobile-menu').innerHeight()) + "px")

        }else{
            document.documentElement.style.setProperty('--top-position-menu', this.$leaderboard.innerHeight() + "px")
        }
    },

    show: function () {
        if (this.$leaderboard) {
            this.$leaderboard.show();
            $(this.$leaderboard.find(".wrapper")[0]).css("display", "flex")
            let leaderboardHeight = this.$leaderboard.innerHeight() + 90;
            document.documentElement.style.setProperty('--top-position-menu', this.$leaderboard.innerHeight() + "px")
            document.documentElement.style.setProperty('--page-margin', this.$leaderboard.innerHeight() + ($('#megaMenu').innerHeight() || $('.desktop-menu').find(".header__row").innerHeight() || $('.mobile-menu').innerHeight()) + "px")
            if(typeof doofinder !== 'undefined'){
                document.getElementById('css-added-rules').sheet.insertRule('#dffullscreen.df-fullscreen { top: ' + leaderboardHeight + 'px; }', 0);
            }
        }

    },

    close: function () {
        CookieUtils.createCookie('leaderboard_closed', '1', 1);

        document.documentElement.style.setProperty('--breadcrumb-position', 95 + "px")
        document.documentElement.style.setProperty('--top-position-menu', 0 + "px")
        document.documentElement.style.setProperty('--page-margin',  ($('#megaMenu').innerHeight() || $('.desktop-menu').find(".header__row").innerHeight() || $('.mobile-menu').innerHeight()) + "px")

        this.$leaderboard.slideUp().promise().done(() => {
            headerLayerPosition.setMarginTop();

            //resize height new-menu
            if(typeof header != "undefined"){
                header.refresh()
            }

        });
        if(typeof doofinder !== 'undefined') {
            document.getElementById('css-added-rules').sheet.insertRule('#dffullscreen.df-fullscreen { top: 100px !important; }', 0);
        }

        $("html").removeClass("isLeaderboardVisible")
    },
    

    bindEvents:  function()  {
        this.show();

        this.$btn_close.on('click', function(){
            this.close();
        }.bind(this));
    },

    init: function () {
        try {
            this.setup();

            if(this.$leaderboard.length ) {
                this.bindEvents();
            }
        }
        catch (e) {
            console.error(e); 
        } 
    }
};

$(document).ready(function () {
    $('.leaderboard .sliders').slick({
        dots: false,
        arrows:false,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay:true,
        autoplaySpeed: 8000,
        variableWidth: true,
    });
    LeaderboardCookie.init();

    let leaderboard = $('.leaderboard')
    if(leaderboard.length > 0){
        let biggestText = 0
        let elementsArray = leaderboard.find(".slick-slide").toArray();

        elementsArray.forEach(function(element) {
            if(biggestText < $(element).innerHeight()){
                biggestText = $(element).innerHeight()
            }
        });

        if(leaderboard.innerHeight() < biggestText){
            leaderboard.css("height", biggestText + 10 + "px")
            document.documentElement.style.setProperty('--top-position-menu', leaderboard.innerHeight() + "px")
            document.documentElement.style.setProperty('--page-margin', leaderboard.innerHeight() + ($('#megaMenu').innerHeight() || $('.desktop-menu').find(".header__row").innerHeight() || $('.mobile-menu').innerHeight()) + "px")
        }
    }

});

$('.leaderboard').on('init', function(event, slick){
    LeaderboardCookie.show();
});

window.addEventListener('resize', function () {
    if(window.innerWidth > 1200) {
        if($('.leaderboard').length === 0){
            document.documentElement.style.setProperty('--page-margin',  ($('#megaMenu').innerHeight() || $('.desktop-menu').find(".header__row").innerHeight() || $('.mobile-menu').innerHeight()) + "px")
        }else{
            document.documentElement.style.setProperty('--page-margin', $('.leaderboard').innerHeight() + ($('#megaMenu').innerHeight() || $('.desktop-menu').find(".header__row").innerHeight() || $('.mobile-menu').innerHeight()) + "px")
        }
    }
    //TODO: IMPROVE --
    // MOBILE
    // $(".leaderboard").load(location.href + " .leaderboard");
    // $("#megaMenu").load(location.href + " #megaMenu");
})